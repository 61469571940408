// author:马云阳；description:众惠-交易-输入密码
<template>
    <section class="PublicFundProcess ">
      <site-nav>
        <span>&gt;</span>
        <RouterLink :to="{name:'FundMarket'}">基金超市</RouterLink>
        <span>&gt;</span>
        <span>{{funddatas.funddata.fundName}}</span>
      </site-nav>
        <!-- <NavRightSide/> -->
        <div class="publicfundprocess_cont fixed_width">
            <a @click="togos" class="title_">
             <span class="iconfont">&#xe665;</span>&emsp;{{funddatas.funddata.fundName}}
            </a>
            <div class="side_cont">
              <ProgressBar :sideData="side"/>
            </div>
            <div class="solids_"></div>
            <div class="card_">
              <div class="turn_">
                  <div class="left_cards">
                    <div class="card_nums_">{{funddatas.banklist.bankAccount||''}}</div>
                  </div>
                  <div class="right_txt">
                    <div class="name_">您即将买入基金<span>{{funddatas.funddata.fundName||''}}</span></div>
                    <div class="price_">{{funddatas.numprice}}<span>元</span></div>
                    <div class="types_">快捷支付</div>
                  </div>
              </div>
              <div class="inputs_">
                <div class="title_input">
                  <span class="l_ti">请输入交易密码:</span>
                  <a class="r_ti" @click="forgetPoss">忘记密码?</a>
                </div>
                <div class="inputs_boxs">
                  <input style="width:100%;height:100%;" :maxlength="6" v-model="password" placeholder="请输入六位交易密码" type="password">
                </div>
                <span v-if="showerr" class="err">{{errmsg}}</span>
              </div>
              <div class="topage_btn">
                <a @click="getbtn?configs():''" class="topage_">确定</a>
                <a @click="toGo" class="navback">返回上一步</a>
              </div>
          </div>
          
        </div>
    </section>
</template>
<script>
// import NavRightSide from "@/components/NavRightSide";
import ProgressBar from "@/components/ProgressBar";
import { fixallotTrade } from "@/api/addfund";
import SiteNav from '@/views/fund-market/components/SiteNav.vue'

export default {
  name: "ProcessPassword",
  components: { ProgressBar ,SiteNav},
  data() {
    return {
      side: [
        { title: "填写定投信息", type: 1 },
        { title: "输入交易密码", type: 2 },
        { title: "操作结果", type: 0 }
      ],
      funddatas: {},
      passwords: null, //密码
      userinfo: {},
      password:null,
      showerr:false,
      errmsg:'',
      getbtn:true
    };
  },
  methods: {
    forgetPoss() {
      this.$router.push('/myFavors/accountManage/safetyCentre')
    },
    topeges() {
      console.log("执行下一步");
      this.$router.go(-2);
    },
       togos(){
      this.$router.go(-1)
    },
    configs() {
      console.log('jashdj',this.funddatas,)
      if (!this.password || this.password < 6) {
        this.$message.error("请正确输入密码");
      } else {
        this.getbtn=false;
        let params = {
          protocolFixDay: this.funddatas.protocolFixDay,
          firstExchdate: this.funddatas.firstExchdate,
          endDate: this.funddatas.endDate,
          protocolPeriodUnit: this.funddatas.protocolPeriodUnit,
          tradePeriod: this.funddatas.tradePeriod,
          balance: this.funddatas.numprice,
          channelSource: this.funddatas.channelSource,
          tradeAcco: this.funddatas.banklist.tradeAcco,
          password: this.password,
          fundCode:this.funddatas.funddata.fundCode,
          payType: this.funddatas.payType,
          bankAccount: this.funddatas.banklist.bankAccount,
        };
        fixallotTrade(params).then(res=>{
          this.showerr=false;
          if(res.code==200){
            let dates=new Date();
            let okey={
              funddata:this.funddatas.funddata,
              dates:dates,
              banklist:this.funddatas.banklist,
              balance:this.funddatas.numprice,
              newdate:this.funddatas.newtimes,
              payType:this.funddatas.payType
            }
            this.$router.push({name:"AddTrueFour",params:{okey}})
          }
          console.log('res',res)
        }).catch(e=>{
          this.getbtn=true;
          this.showerr=true;
          this.errmsg=e
        })
      }

      // subscribe(params).then(res => {
      //   if (res.code != 200) {
      //     this.$message.error(res.msg);
      //   } else {
      //     let date = new Date();
      //     let okey = { funddatas: this.funddatas, dates: date };
      //     this.$router.push({ name: "BuyingFundfour", params: { okey } });
      //   }
      // });
    },
    toGo() {
      this.$router.go(-1);
    }
  },
  created() {
    this.funddatas = this.$route.params.params;
    this.userinfo =
      JSON.parse(window.localStorage.getItem("customerInfo")) || {};
    console.log("ids", this.funddatas);
  }
};
</script>
<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-input-placeholder {
  color: #bdc0cb;
}
::-moz-placeholder {
  color: #bdc0cb;
}

/* firefox 19+ */
:-ms-input-placeholder {
  color: #bdc0cb;
}

/* ie */
input:-moz-placeholder {
  color: #bdc0cb;
}

.PublicFundProcess {
  // padding: 0px 0px 50px 0px;
  min-height: 600px;
  // display: flex;
  background: #F3F4F6;
  .publicfundprocess_cont {
    background: #fff;
    min-height: 1290px;
    width: 1200px;
    .title_ {
      display: inline-block;
      padding: 40px 40px 0px 40px;
      height: 60px;
      font-size: 20px;
      color: #1f1f1f;
    }
    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }
    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }
    .card_ {
      padding: 0px 0px 0px 210px;
      margin-top: 70px;
      .titles_ {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
        font-weight: 700;
        color: #1f1f1f;
      }
      .turn_ {
        margin-top: 30px;
        display: flex;
        align-items: center;
        .left_cards {
          width: 268px;
          height: 161px;
          background-image: url('../../static/img/my/bank card.png');
          background-repeat: no-repeat;
          border-radius: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          .card_nums_ {
            font-size: 18px;
            font-family: Bahnschrift, Bahnschrift-Light;
            font-weight: 300;
            color: #ffffff;
            letter-spacing: 4px;
          }
        }
        .right_txt {
          margin-left: 58px;
          .name_ {
            font-size: 16px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
            font-weight: 300;
            color: #1f1f1f;
            span {
              color: #ce9b63;
              margin-left: 2px;
            }
          }
          .price_ {
            margin-top: 10px;
            font-size: 26px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
            font-weight: 700;
            color: #e45247;
            span {
              font-size: 14px;
              margin-left: 4px;
            }
          }
          .types_ {
            font-size: 16px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
            font-weight: 300;
            color: #1f1f1f;
            margin-top: 10px;
          }
        }
      }
      .inputs_ {
        width: 520px;
        margin-top: 40px;
        .title_input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .l_ti {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #1f1f1f;
          }
          .r_ti {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #a5a5a5;
          }
        }
        .inputs_boxs {
          display: flex;
          align-items: center;
          width: 494px;
          height: 46px;
          border: 1px solid #e0e2e8;
          border-radius: 2px;
          padding-left: 24px;
          margin-top: 14px;
        }
        .err{
          display: inline-block;
          margin: 10px 0px;
          color: red;
        }
      }
      .topage_btn {
        margin-top: 40px;
        display: flex;
        align-items: center;
        .topage_ {
          width: 240px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          color: #ce9b63;
          background: linear-gradient(101deg, #f7d9b7 0%, #eabf96 100%);
          border-radius: 2px;
          font-size: 16px;
          margin-right: 20px;
        }
        .navback {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #ce9b63;
        }
      }
    }
  }
}
</style>
